@font-face {
    font-family: "Graphik";
    src: url("./fonts/graphikRegular.otf") format("otf"),
    url("./fonts/graphikRegular.svg") format("svg"),
    url("./fonts/graphikRegular.ttf") format("ttf"),
    url("./fonts/graphikRegular.woff") format("woff");
    font-weight: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("./fonts/graphikSemibold.otf") format("otf"),
    url("./fonts/graphikSemibold.svg") format("svg"),
    url("./fonts/graphikSemibold.ttf") format("ttf"),
    url("./fonts/graphikSemibold.woff") format("woff");
    font-weight: bold;
}

@font-face {
    font-family: "Graphik";
    src: url("./fonts/graphikMedium.eot") format("eot"),
    url("./fonts/graphikMedium.svg") format("svg"),
    url("./fonts/graphikMedium.ttf") format("ttf"),
    url("./fonts/graphikMedium.woff") format("woff");
    font-weight: 500;
}

:root {
    --wz-primary-color: #0032FA;
    --wz-background-color: #F5F5F5;
    --wz-banner-url: none;
    --wz-banner-blur: 0;
    --wz-banner-background-disabled: 1;
    --wz-light-primary-color: #F5F5F5;
    --wz-font-family: "Graphik";
}

html.gemino, body.gemino-minisite {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
}

html.gemino {
    scroll-padding-top: 100px;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

body.gemino-minisite {
    margin: 0;
    font-size: 14px !important;
    font-family: var(--wz-font-family) !important;
    background-color: var(--wz-background-color) !important;
    overflow: hidden;
    overflow-y: auto;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    box-sizing: border-box;
    display: block;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;

    * {
        font-family: var(--wz-font-family) !important;
    }

    /*b {
        font-weight: 500;
    }
    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.5em;
    }*/
    .gemino-margin-top-50 {
        margin-top: 50px;
    }
    .gemino-navbar {
        margin-bottom: 15px;
    }

    // old css for blur image in background

    // .gemino-bg-container {
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     background-color: var(--wz-background-color);
    // }
    // .gemino-bg-image {
    //     position: absolute;
    //     top: -15px;
    //     left: -15px;
    //     right: -15px;
    //     height: calc(var(--wz-banner-background-disabled) * 400px);
    //     /* The image used */
    //     background-image: var(--wz-banner-url);

    //     /* Center and scale the image nicely */
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     overflow: hidden;

    //     .gemino-bg-blur {
    //         background: rgba(255, 255, 255, calc(var(--wz-banner-background-disabled) * var(--wz-banner-blur) * 0.02));
    //         backdrop-filter: blur(calc(var(--wz-banner-background-disabled) * var(--wz-banner-blur)*1px));
    //         height: calc(var(--wz-banner-background-disabled) * 100vh);
    //         width: 100%;
    //     }
    // }

    .gemino-img-container {
        display: flex;
        justify-content: center;
        position: relative;
        overflow: hidden;
        width: 100%;
        margin: 0 auto;
        aspect-ratio: 16/9;
        border-radius: 10px;
        /* The image used */
        background-image: var(--wz-banner-url);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        
        &.banner-too-small {
            background-size: inherit;
        }
    }
    .gemino-card-calendar{
        position: absolute;
        top: -40px;
        left: 1.25rem;
        background-color: #FFF;
        padding: 7px 15px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 3px 3px 0 -1px rgba(223, 56, 56, 0.25);
    }
    .gemino-card-calendar .month {
        color: #fa3e4e;
    }
    .gemino-card-calendar .day {
        // Bold Weight
        font-weight: bold;
        line-height: 1em;
        font-size: 1.5em;
    }
    .gemino-container {
        width: 840px;
        margin: 0 auto;
        margin-top: 20px;
        background-color: #FFF;
        border-radius: 10px;
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;

        @media (max-width: 840px) {
            width: 100%;
            margin-top: 0;
        }

        &.gemino-footer{
            background-color: transparent;
        }

        .gemino-row {
            width: 90%;
            display: flex;
            padding-bottom: 20px;
            margin: 0 auto;
        }

        .gemino-column-left {
            flex: 1;
            padding: 20px 0;

            #gemino-ticketing-container {
                overflow: hidden;
            }
            .gemino-card-body {
                padding: 15px;
                margin-bottom: 1rem;

                &.gemino-card-widget {
                    margin-bottom: 40px;
                }
            }
        }
        .gemino-card-body {
            flex: 1 1 auto;
            min-height: 1px;
            padding: 0;
        }
        .gemino-card-files {
            & > ul {
                list-style-type: none;
                padding-left: 0;

                & > li {
                    margin-bottom: 5px;
                    display: flex;

                    & > svg {
                        flex-shrink: 0;
                        margin-right: 5px;
                        height: 1em;
                        width: 1em;
                        > g {
                            fill: var(--wz-primary-color);
                        }
                    }
                    & > a{
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        word-break: break-word;
                        text-overflow: ellipsis;
                        color: #000;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .gemino-column-right {
            width: 300px;
            padding: 20px;
        }
    }
    .gemino-container.gemino-footer {
        padding: 10px 0 50px 0;
        font-size: 12px;

        .gemino-footer-weezevent {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: #555a69;

            a {
                color: #555a69;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

    }

    .gemino-container-contact{
        display: flex;
        justify-content: space-between;
        @media (max-width: 650px) {
            flex-direction: column;
        }
    }

    .gemino-btn-contact{
        margin-top: -20px;
        @media (max-width: 650px) {
            margin-top: 20px;
            width: 100%;
            button {
                width: 100%;
            }
        }
    }

    .gemino-data-contact {
        display: flex;
        flex-direction: row;
        margin-bottom: 12px;

        .gemino-data-event-website, .gemino-data-event-blog {

            a {
                color: #000000;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        .gemino-data-glyph {
            flex-shrink: 0;
            margin-right: 5px;
            position: relative;
            top: 1px;
        }
        .gemino-data-content {
            line-break: anywhere;
        }
    }

    .gemino-data-event-title {
        margin-top: 0;
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .gemino-data-event-surtitle {
        text-transform: uppercase;
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 0;
        color: var(--wz-primary-color);
        font-weight: normal;
    }
    .gemino-data-event-subtitle {
        font-size: 14px;
        margin-bottom: 10px;
        margin-top: 0;
        color: #C4C4C4;
        font-weight: normal;
    }
    .gemino-data-event-info {
        display: none;
        align-items: center;
    }
    .gemino-data-event-container{
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin-top: 8px;

        .gemino-data-event-location {
            display: flex;
            flex-direction: column;
            line-height: 1.4;
        }
        .gemino-data-event-date {
            color: var(--wz-primary-color);
            font-weight: 500;
        }
        .gemino-data-event-date-icon-path{
            fill: var(--wz-primary-color)
        }
    }
    .gemino-info-text {
        padding-left: 8px;
    }
    @media (max-width: 650px) {
        .gemino-img-container {
            background-image: unset;
            height: unset;
            padding-bottom: unset;
        }
    }

    .gemino-img-banner {
        display: none;

        @media (max-width: 650px) {
            display: block;
            width: 100%;
        }
    }
    .gemino-main-content {
        padding-top: 49px;
        background-color: #FFF;
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 85.7%;
        margin: 0 auto;

        @media (max-width: 840px) {
            width: 91.6%;
        }
    }
    .gemino-card-title {
        margin: 0 0 .75rem;
        // Bold Weight
        font-weight: bold;
        line-height: 1.2;
        font-size: 1.2em;
    }
    h2, h2.gemino-card-title {
        font-size: 1.6em;
    }
    h3, h3.gemino-card-title {
        font-size: 1.1em;
    }
    h4, h4.gemino-card-title {
        font-size: 1em;
    }
    .gemino-sticky {
        position: sticky;
        top: 0;
    }
    .gemino-sticky-top-120 {
        top: 80px;
    }
    .gemino-remove-margin {
        margin: 0;
    }
    .gemino-self-center {
        align-self: center;
    }
    .gemino-price-start {
        // Bold Weight
        font-weight: bold;
    }
    .gemino-lighter-card .gemino-card-title {
        margin-bottom: 15px;
        margin-top: 0;
    }
    .gemino-lighter-card {
        border-radius: 10px;
        &#organization {
            background-color: var(--wz-light-primary-color);
            .gemino-card-text {
                display: block;
            }
        }
        .gemino-card-text {
            text-align: left;
            display: inline-flex;
            margin-top: 0;

            .gemino-card-content {
                display: flex;
                flex-direction: column;
                padding-left: 5px;

                > span {
                    line-height: 1.3em;
                }
            }

            svg {
                margin-right: 3px;
            }
        }
    }

    .gemino-lighter-card .gemino-card-body{
        padding: 23px 20px;
        margin-bottom: 1rem;
    }
    .gemino-data-event {
        display: flex;
        align-items: center;
        background-color: #FFF;
        width: 100%;
        box-sizing: border-box;
        margin: 20px auto;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow:0px 2px 4px rgba(0, 0, 0, 0.15);
        -moz-box-shadow:0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        padding: 23px 1.25rem;
        z-index: 20;

        &.gemino-sticky {
            position: sticky;
            top: -1px;
            @media (min-width: 650px) {
                &.is-pinned{
                    .gemino-data-event-content{
                        > *:not(.gemino-data-event-title) {
                            display: none;
                        }
                    }
                }
            }
            @media (max-width: 650px) {
                position: static;
            }
        }
    }
    .gemino-data-event.gemino-padding-regular {
        padding: 10px 1.25rem;
    }
    .gemino-data-event-content {
        width: 100%;
        flex: 3;
    }
    .gemino-data-event-content h5 {
        // Bold Weight
        font-weight: bold;
    }
    .gemino-data-event-cta {
        flex: 1;
        align-self: center;
        @media (max-width: 650px) {
            width: calc(100% - 90px);
            background-color: white;
            padding: 15px;
            border-radius: 10px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
            position: fixed;
            z-index: 2;
            bottom: 30px;
        }
    }
    .gemino-data-event-pricing {
        flex: 1;
        align-self: center;
        text-align: right;
        margin-right: 1.8em;
        font-size: 1.2em;
    }
    .gemino-card {
        margin-bottom: 15px;
    }
    .gemino-card-text.gemino-phone, .gemino-card-text.gemino-email {
        margin-bottom: 2px;
        margin-top: 0;
    }


    .gemino-card-text.gemino-phone svg, .gemino-card-text.gemino-email svg {
        margin-right: 10px;
    }
}
// old css for blur image in background

// @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
//     body.gemino-minisite .gemino-bg-image {
//         .gemino-bg-blur {
//             -webkit-backdrop-filter: blur(calc(var(--wz-banner-background-disabled)*var(--wz-banner-blur)*1px));
//             backdrop-filter: blur(calc(var(--wz-banner-background-disabled)*var(--wz-banner-blur)*1px));
//         }
//     }
// }

// /* blur fallback for Firefox (not supporting backdrop-filter) */
// @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
//     body.gemino-minisite .gemino-bg-image {
//         filter: blur(calc(var(--wz-banner-background-disabled)*var(--wz-banner-blur)*1px));
//         -webkit-filter: blur(calc(var(--wz-banner-background-disabled)*var(--wz-banner-blur)*1px));

//         .gemino-bg-blur {
//             display: none;
//         }
//     }
// }

.block_video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    .iframe_resize {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.gemino-column-right:empty {
    display: none;
}
.gemino-card-sticky {
    position: sticky;
    top: 100px;
    margin-top: -115px;
}
.gemino-card-sticky .gemino-time-left {
    font-weight: bold;
    font-size: 1.5em;
}
.gemino-card-sticky .gemino-price-start {
    font-weight: bold;
    font-size: 1.1em;
}
.gemino-card-text.gemino-timeline {
    margin-bottom: 5px;
}
#gemino-more .gemino-card-body {
    overflow: hidden;
}
.gemino-direction {
    font-size: 14px;
    margin-bottom: .5em;

    .gemino-content, .gemino-get-direction {
        font-size: 14px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
.gemino-maps-container {
    border-radius: 5px;
    height: 150px;
    width: 100%;
    overflow: hidden;
    text-align: center;
}
#gemino-google-maps > img {
    width: 100%;
}
.gm-style div{
    cursor: default;
}
h5 {
    font-size: 1.2em;
}
.gemino-direction {
    display: flex;
    justify-content: space-between;
}
.gemino-get-direction{
    cursor: pointer;
    color: var(--wz-primary-color);
}
.gemino-avatar-container {
    width: 100%;
    position: relative;

    img{
        cursor: pointer;
        width: 30px;
        border-radius: 100px;
    }
}

img.gemino-portrait-img{
    width: 100%;
    height: auto;
}
iframe.gemino-custom-iframe {
    width: 100%;
    min-height: 250px;
    height: auto;
}
.bi-plus-circle-fill {
    cursor: pointer;
    height: 30px;
    color: #D3D3D3;
}
.gemino-report-container {
    text-align: center;
    font-size: .9em;
    margin-bottom: 10px;
    padding-bottom: 10px;
}
#gemino-count-timer{
    display: none;
}

.gemino-loader {
  border: 1px solid #f3f3f3; /* Light grey */
  border-top: 1px solid #767676; /* Grey */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin .5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#gemino-attendees {
    #gemino-attendees-error {
        color: red;
        display: none;
        &.display {
            display: block;
        }
    }

    #gemino-attendees-footer {
        display: none;
        div {
            padding: 10px 20px;
            display: block;
            text-align: center;
            cursor: pointer;
        }
    }
}

.gemino-btn {
    background-color: var(--wz-primary-color);
    border-color: var(--wz-primary-color);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    display: block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #FFF;
    text-decoration: none;
    cursor: pointer;

    &:hover, &:focus, &:active {
        background-color: var(--wz-primary-color)!important;
        opacity: 0.9;
        border-color: var(--wz-primary-color)!important;
        color: #FFF;
    }
}
.gemino-btn-buy {
    background-color: var(--wz-primary-color);
    border-color: var(--wz-primary-color);

    &:hover, &:focus, &:active {
        background-color: var(--wz-primary-color)!important;
        border-color: var(--wz-primary-color)!important;
    }
}


a {
    color: var(--wz-primary-color);
}

a:hover {
    color: var(--wz-primary-color);
}
.gemino-contact-us {
    margin-top: 8px;

    a {
        color: var(--wz-primary-color);
        font-size: 14px;
        text-decoration: none;

        &:hover {
            color: var(--wz-primary-color);
            text-decoration: underline;
        }
    }
}
.report-container .bullet-container svg{
    color: #D3D3D3;
}
.add-to-calendar {
    margin-bottom: 15px;
}
.add-to-calendar svg {
    margin-top: -2px;
}
.button-like {
    border: solid 1px var(--wz-primary-color);
    border-radius: 5px;
    width: 100%;
    display: block;
    padding: 8px 15px;
    text-align: center;
}

.button-like:hover {
    background-color: var(--wz-primary-color);
    color: #FFF;
}

body.gemino-minisite .gemino-container .gemino-card-body.gemino-smartphone-only {
    display: none;
    padding: 15px 20px 0;
}


.gemino-video, .gemino-img {
    margin-bottom: 20px;
}

.gemino-img > img {
    width: 100%;
}

.gemino-social {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    // Medium Weight
    font-weight: 500;
    font-size: 1.25em;
    flex-direction: column;

    .gemino-icons {
        padding: 10px 10px 0;

        svg {
            height: 20px;
            color: var(--wz-primary-color);
        }

        a {
            text-align: center;
            margin-right: 5px;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}


.gemino-bad-password-message {
    color: red;
    margin-bottom: 10px;
    display: none;
}

.gemino-bad-password {
    .gemino-bad-password-message {
        display: block;
    }
}

.gemino-password-container {
    max-width: 350px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px auto;

    img.gemino-img{
        width: 100px;
        height: auto;
        margin-bottom: 25px;
    }

    .gemino-form-group {
        margin-bottom: 15px;

        input.gemino-input {
            border-radius: 5px;
            border: 1px solid #D3D3D3;
            padding: 10px 20px;
            font-size: 12px;
        }
    }

    .gemino-btn {
        width: 100%;
    }
}

/* Slideshow container */
.gemino-carousel {
    margin-bottom: 20px;
}
.gemino-slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
}

/* Hide the images by default */
.gemino-slides {
    display: none;
    height: 250px;
    background: center / contain no-repeat;
}

/* Next & previous buttons */
a.gemino-prev, a.gemino-next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 200px;
    padding: 3px 12px;
    user-select: none;
    background-color: var(--wz-primary-color);
}
a.gemino-prev:hover, a.gemino-next:hover {
    color: #FFF;
}

/* Position the "next button" to the right */
.gemino-next {
    right: 5px;
}
.gemino-prev {
    left: 5px;
}

/* The dots/bullets/indicators */
.gemino-dot-container {
    text-align: center;
    margin-top: 10px;
}
.gemino-dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.gemino-active, .gemino-dot:hover {
    background-color: var(--wz-primary-color);
}

/* Fading animation */
.gemino-fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}
.gemino-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.gemino-field input, .gemino-field textarea {
    border-radius: 5px;
    border: 1px solid #D3D3D3;
    background-color: transparent;
    padding: 8px 15px;

}
.gemino-field input:focus, .gemino-field textarea:focus {
    outline: none;
    border:1px solid var(--wz-primary-color);
    box-shadow: 0 0 2px var(--wz-primary-color);

}
.gemino-modal-dialog {
    width: 80%
}


/* MODAL
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.gemino-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 30; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
}

.gemino-modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    border-radius: 5px;
    max-width: 500px;
}

.gemino-open-modal {
    border: 0;
    background-color: transparent;
    text-align: left;
    padding: 0;
    color: var(--wz-primary-color);
    cursor: pointer;

    &:hover {
    text-decoration: underline;
    }
}

/* The Close Button */
.wz-font-cross {
  cursor: pointer;
}

.gemino-modal-close:hover,
.gemino-modal-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.gemino-modal-footer {
    display: flex;
    justify-content: flex-end;
}

.gemino-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 15px;
    font-weight: 500;
}

textarea {
    resize: none;
}

/* ANIMATIONS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
[data-animation] .gemino-modal-dialog {
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.51, 0.92, 0.24, 1.15);
}

[data-animation].gemino-is-visible .gemino-modal-dialog {
    opacity: 1;
    transition-delay: 0.2s;
}

[data-animation="slideInOutLeft"] .gemino-modal-dialog {
    transform: translateX(-100%);
}

[data-animation="slideInOutLeft"].gemino-is-visible .gemino-modal-dialog {
    transform: none;
}

@keyframes mixInAnimations {
    0% {
        transform: translateX(-100%);
    }

    10% {
        transform: translateX(0);
    }

    20% {
        transform: rotate(20deg);
    }

    30% {
        transform: rotate(-20deg);
    }

    40% {
        transform: rotate(15deg);
    }

    50% {
        transform: rotate(-15deg);
    }

    60% {
        transform: rotate(10deg);
    }

    70% {
        transform: rotate(-10deg);
    }

    80% {
        transform: rotate(5deg);
    }

    90% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}


/* FOOTER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.gemino-page-footer {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
}

.gemino-page-footer span {
    color: #e31b23;
}

@-webkit-keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}

@keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}

@media (max-width: 840px) {

    body.gemino-minisite {
        .gemino-margin-top-50 {
            margin-top: 10px;
        }
        .gemino-column-left {
            padding-bottom: 0;
        }
        .gemino-column-right {
            padding-top: 0;
        }
    }

    img.portrait-img{
        width: 100%;
        height: auto;
    }
    body.gemino-minisite {
        padding-left: 0;
        padding-right: 0;

        .gemino-bg-container {
            display: none;
        }
        .gemino-container {
            width: 100%;
            padding-top: 5px;
            /*padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;*/

            .gemino-row {
                flex-direction: column;

                .gemino-column-left {
                    flex:  1;
                }

                .gemino-column-right {
                    flex:  1;
                    width: auto;
                    padding: 20px;

                    .gemino-card-body {
                        padding: 0;
                    }
                }

                .gemino-card-title {
                    font-size: 1.6em;
                    margin-bottom: 10px;
                }

                .gemino-contact-us {
                    margin-top: 0;
                }
                .gemino-lighter-card {
                    .gemino-card-text .gemino-card-content {
                        display: inline-block;
                    }
                }
                #location .gemino-card-text {
                    margin-bottom: 5px;
                }

                .gemino-direction .gemino-content, .gemino-direction .gemino-get-direction {
                    font-size: 14px;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .gemino-lighter-card .gemino-card-body {
                    background-color: transparent;
                    margin-right: 0;
                    margin-left: 0;
                    a {
                        color: var(--wz-primary-color) !important;
                    }
                }
            }
        }
    }

    iframe.custom-iframe {
        width: 100%;
        min-height: unset;
    }

    #gemino-nav-container-top {
        display: block;
        background-color: transparent;
        height: 1px;
    }

    #gemino-google-maps {
        left: 0;
    }

    .gemino-container{
        padding-top: 5px;
    }

    body.gemino-minisite .gemino-data-event{
        padding: 20px 1.25rem;
        flex-direction: column;
    }

    .gemino-data-event-cta {
        width: 100%;
        margin-top: 15px;
    }

    .gemino-data-event-pricing {
        margin: 0;
    }

    .gemino-data-event-title {
        margin: 0;
    }

    .gemino-data-event-content .gemino-data-event-info {
        display: none!important;
    }

    .gemino-footer {
        text-align: center;
    }
    #gemino-ticketing-container {
        display: grid;
        margin-left: -1.25rem;
        margin-right: -1.25rem;

        > a {
            margin-right: 20px;
        }
    }
    .gemino-compromise-sticky {
        display: block;
    }

    .gemino-card-body.gemino-smartphone-only {
        display: block;
    }
    .gemino-data-event-content {
        align-self: center;
    }
}
